import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/make',
    name: 'make',
    component: () => import(/* webpackChunkName: "make" */ '../views/MakeView.vue')
  },
  {
    path: '/catalog/:slug',
    name: 'catalog',
    component: () => import(/* webpackChunkName: "product" */ '../views/CatalogView.vue')
  },
  {
    path: '/product/:slug',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '../views/ProductView.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
