<template>
    <div>
        <v-app-bar class="primary hidden-sm-and-down">
            <div class="d-flex align-center">
            <v-img src="@/assets/logo.png" alt="Relikt store" class="shrink mr-10" contain  transition="scale-transition" width="100"/>
            </div>
            <v-btn text tag="router-link" :to="{ path: '/' }" class="white--text">Главная</v-btn>
            <v-btn text tag="router-link" :to="{ path: '/about/' }" class="white--text">О нас</v-btn>
            <v-btn text tag="router-link" :to="{ path: '/make/' }" class="white--text">Изделия на заказ</v-btn>

            <v-spacer></v-spacer>

            <v-btn text @click="toggleManaged">
                <v-icon v-if="managed" color="red">fa-heartbeat</v-icon>
                <v-icon v-else >fa-heart</v-icon>
            </v-btn>
        </v-app-bar>

        <v-app-bar class="primary hidden-md-and-up" >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-img src="@/assets/logo.png" alt="Relikt store" class="shrink ml-5" contain  transition="scale-transition" width="100"/>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" class="fill-height" absolute temporary>
            <v-list dense>
                <v-list-item link tag="router-link" :to="{ path: '/' }">
                    <v-list-item-title>Главная</v-list-item-title>
                </v-list-item>
                <v-list-group>
                    <template v-slot:activator>
                        <v-list-item-title>Каталог</v-list-item-title>
                    </template>
                    <v-list-item link v-for="(cat, index) in categories" :key="index"
                                tag="route-link" :to="{ path: '/catalog/' + cat.Slug }">
                        <v-list-item-title>{{ cat.Title }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-divider></v-divider>
                <v-list-item link tag="router-link" :to="{ path: '/about/' }">
                    <v-list-item-title>О нас</v-list-item-title>
                </v-list-item>
                <v-list-item link tag="router-link" :to="{ path: '/make/' }">
                    <v-list-item-title>Изделия на заказ</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "NavMenu",
    props: ["categories"],

    data: () => ({
        drawer: false,
    }),

    methods: {
        toggleManaged: function() {
            axios.post("/manage/toggle/")
                .then( (res) => console.log(res) )
                .catch( (err) => console.log(err) )
        }
    }
    
};
</script>