<template>
    <div>
        <v-row>
            <v-col cols="12">
                <Breadcrumbs />
            </v-col>
        </v-row>

        <v-row class="d-flex flex-row align-stretch">
            <v-col cols="12" sm="4" lg="3" xl="2" v-for="(item, i) in products" :key="i" class="d-flex flex-wrap">
                <ProductCard :product="item" :managed="managed" @refresh="getProducts()" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ProductCard from '@/components/ProductCard.vue';

export default {
    name: 'HomeView',

    components: { Breadcrumbs, ProductCard },
    data: () => ({
        products: [],
        managed: true,
    }),
    methods: {
        getProducts() {
            axios.get(`/api/v1/category/_all_/`)
                .then((res) => {
                    this.products = res.data.products
                    this.managed = res.data.managed == "true"
                })
                .catch((err) => console.log(err));
        },
    },
    watch: {
        $route() { this.getProducts() },
    },
    mounted() {
        this.getProducts();
    },
}
</script>
