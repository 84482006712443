<template>
    <v-dialog v-model="show" max-width="400">
        <v-card>
            <v-card-title>Удаление категории</v-card-title>
            <v-card-text>
                Вы уверены что хотите йдалить категорию <strong>{{ cat.Title }}</strong> ?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="deleteCategory">Удалить</v-btn>
                <v-btn text @click="show = false">Отмена</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
    name: "CategoryDelete",
    props: ["value", "category"],

    computed: {
        show: {
            get() { return this.value },
            set(value) { return this.$emit('input', value)}
        },
        cat: function() {
            return JSON.parse(JSON.stringify(this.category))
        }
    },
    methods: {
        deleteCategory() {
            axios.delete(`/manage/category/${this.cat.ID}`)
                .then( () => {
                    this.show = false
                    this.$emit('refresh')
                })
                .catch( (err) => console.log(err) )
        }
    },
}
</script>