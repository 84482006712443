<template>
    <v-dialog v-model="show" max-width="600px">
        <v-card>
            <v-card-title>Редактирование категории</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="cat.Title" label="Название" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="cat.Slug" label="Ссылка" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" text @click="updateCategory">Изменить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import axios from 'axios';

export default {
    name: "CategoryUpdate",
    props: ["value", "category"],

    data: () => ({

    }),
    computed: {
        show: {
            get() { return this.value },
            set(value) { return this.$emit('input', value)}
        },
        cat: function() {
            return JSON.parse(JSON.stringify(this.category))
        }
    },
    methods: {
        updateCategory() {
            if (this.cat.Title != null && this.cat.Slug != null) {
                axios.put("/manage/category/", this.cat)
                    .then( () => {
                        this.show = false
                        this.$emit('refresh')
                    }).catch( (err) => console.log(err) )
            }
        },
    }
}   
</script>