<template>
    <v-dialog v-model="show" persistent max-width="800px">
        <v-card>
            <v-card-title>Изменить товар  {{ prod.Title }}</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field dense v-model="prod.Title" label="Название" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field dense v-model="prod.Slug" label="Ссылка" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-combobox dense v-model="prod.Materials" label="Материалы"
                                        :items="autocomplete.materials" multiple clearable chips></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field dense v-model.number="prod.Price" label="Цена, руб." required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4">
                            <v-text-field dense v-model.number="prod.Dimensions.Length" label="Длина, мм." required></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field dense v-model.number="prod.Dimensions.Width" label="Ширина, мм." required></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field dense v-model.number="prod.Dimensions.Height" label="Высота, мм." required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field dense v-model.number="prod.Diameter" label="Диаметр сопла, мм" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field dense v-model.number="prod.Weight" label="Вес, гр." required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-combobox dense v-model="prod.Description" label="Описание"
                                        :items="autocomplete.descriptions" multiple clearable chips></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-combobox dense v-model="prod.Images" label="Ссылки на изображения"
                                        multiple clearable chips></v-combobox>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="show = false">Отмена</v-btn>
                <v-btn color="green" text @click="updateProduct">Изменить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
    name: "ProductCreate",
    props: ["value", "product", "autocomplete"],

    data: () => ({
        
    }),
    computed: {
        show: {
            get() { return this.value },
            set(value) { return this.$emit('input', value)}
        },
        prod: function() {
            return JSON.parse(JSON.stringify(this.product))
        }
    },
    methods: {
        updateProduct() {
            this.show = false
            axios.put(`/manage/product/`, this.prod)
                .then( () => {
                    this.$emit('refresh')
                })
                .catch( (err) => console.log(err) )
        },
    },
}
</script>