<template>
    <v-dialog v-model="show" max-width="400">
        <v-card>
            <v-card-title>Удаление товара</v-card-title>
            <v-card-text>
                Вы уверены что хотите удалить товар <strong>{{ product.Title }}</strong> ?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="deleteProduct">Удалить</v-btn>
                <v-btn text @click="show = false">Отмена</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
    name: "ProductDelete",
    props: ["value", "product", "managed"],

    computed: {
        show: {
            get() { return this.value },
            set(value) { return this.$emit('input', value)}
        }
    },
    methods: {
        deleteProduct() {
            axios.delete(`/manage/product/${this.product.ID}`)
                .then( () => {
                    this.show = false
                    this.$emit('refresh')
                })
                .catch( (err) => console.log(err) )
        }
    },
}
</script>