import { render, staticRenderFns } from "./CategoryMenu.vue?vue&type=template&id=331041c3&"
import script from "./CategoryMenu.vue?vue&type=script&lang=js&"
export * from "./CategoryMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../.nvm/versions/node/v18.15.0/lib/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports