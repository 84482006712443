<template>
    <v-card class="d-flex flex-column">
        <v-img contain :src="product.Images[0]" position="top center"></v-img>
        <v-card-title class="text-center break-text">{{ product.Title }}</v-card-title>
        <v-divider></v-divider>
        <v-card-title class="pb-0">
            {{ product.Price }} руб.
            <v-spacer></v-spacer>
            <v-btn v-if="managed" icon outlined @click="openUpdateProductDialog"><v-icon color="orange">fa-pencil</v-icon></v-btn>
            <v-btn v-if="managed" icon outlined @click="deleteDialog = true"><v-icon color="red">fa-trash</v-icon></v-btn>
        </v-card-title>
        <v-card-actions>
            <v-btn block text link tag="route-link" :to="{ path: '/product/' + product.Slug }">Подробнее</v-btn>
        </v-card-actions>
        <ProductDelete v-model="deleteDialog" @refresh="$emit('refresh')" :product="product"  />
        <ProductUpdate v-model="updateDialog" @refresh="$emit('refresh')"
                       :product="product" :autocomplete="autocomplete"/>
    </v-card>
</template>

<script>
import axios from 'axios';
import ProductDelete from './ProductDelete.vue';
import ProductUpdate from './ProductUpdate.vue';

export default {
    name: "ProductCard",
    props: ["product", "managed"],
    components: { ProductDelete, ProductUpdate },

    data: () => ({
        deleteDialog: false,
        updateDialog: false,
        autocomplete: {},
    }),
    methods: {
        openUpdateProductDialog() {
            this.updateDialog = true
            axios.get("/manage/autocomplete/")
                .then( (res) => this.autocomplete = res.data)
                .catch( (err) => console.log(err) )
        }
    }
}
</script>

<style>
    .break-text {
        word-break: break-word;;
    }
</style>