<template>
    <v-footer padless>
        <v-row justify="center" no-gutters>
            <v-col class="primary text-center pt-1" cols="12">
                <v-btn x-large icon href="https://t.me/relikt_spb" target="_blank">
                    <v-icon class="white--text">fa-telegram</v-icon>
                </v-btn>
                <v-btn x-large icon href="http://instagram.com/relikt_spb" target="_blank">
                    <v-icon class="white--text">fa-instagram</v-icon>
                </v-btn>
                <v-btn x-large icon href="https://pin.it/3wRIFFn" target="_blank">
                    <v-icon class="white--text">fa-pinterest</v-icon>
                </v-btn>
            </v-col>
            <v-col
                class="primary py-1 text-center white--text"
                cols="12"
            >
                {{ new Date().getFullYear() }} — <strong>Relikt</strong>
            </v-col>
        </v-row>
    </v-footer>
</template>


<script>
export default {
    name: "FooterBar",
    data: () => ({

    })
}
</script>