<template>
    <v-card class="mx-auto" elevation="0">
        <v-list>
            <v-subheader>
                Категории товаров
                <v-spacer></v-spacer>
                <v-btn v-if="managed" icon outlined color="green" @click="createDialog = true">
                    <v-icon>fa-plus</v-icon>
                </v-btn>
                <CategoryCreate v-model="createDialog" @refresh="loadCategories" />
            </v-subheader>
            <v-list-item-group>
                <v-list-item v-for="(cat, i) in categories" :key="i"
                             tag="route-link" :to="{ path: '/catalog/' + cat.Slug }">
                    <v-list-item-content>
                        <v-list-item-title> {{ cat.Title }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="managed">
                        <v-btn small icon outlined @click="updateCategory(cat)">
                            <v-icon small color="orange">fa-pencil</v-icon></v-btn>
                    </v-list-item-action>
                    <v-list-item-action v-if="managed">
                        <v-btn small icon outlined @click="deleteCategory(cat)">
                            <v-icon small color="red">fa-trash</v-icon></v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <CategoryUpdate :category="editCategory" v-model="updateDialog" @refresh="loadCategories" />
        <CategoryDelete :category="editCategory" v-model="deleteDialog" @refresh="loadCategories" />
    </v-card>
</template>

<script>
import CategoryCreate from './CategoryCreate.vue';
import CategoryDelete from './CategoryDelete.vue'
import CategoryUpdate from './CategoryUpdate.vue';

export default {
    name: "CategoryMenu",
    props: ["categories", "managed"],
    components: { CategoryCreate, CategoryDelete, CategoryUpdate },

    data: () => ({
        createDialog: false,
        deleteDialog: false,
        updateDialog: false,
        editCategory: {},
    }),
    methods: {
        loadCategories() {
            this.$emit('refresh')
        },
        deleteCategory(category) {
            this.editCategory = category
            this.deleteDialog = true
        },
        updateCategory(category) {
            this.editCategory = category
            this.updateDialog = true
        }
    },
}
</script>