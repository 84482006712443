<template>
  <v-app>
    <NavMenu :categories="categories" />
    <v-main>
        <v-container fluid>
            <v-row>
                <v-col cols="0" md="3" class="hidden-sm-and-down">
                    <CategoryMenu :categories="categories" :managed="managed" @refresh="loadCategories" />
                </v-col>
                <v-col cols="12" md="9">
                    <router-view />
                </v-col>
            </v-row>
        </v-container>
    </v-main>
    <FooterBar />
  </v-app>
</template>

<script>
import axios from "axios";

import CategoryMenu from '@/components/CategoryMenu.vue';
import FooterBar from '@/components/FooterBar.vue';
import NavMenu from "@/components/NavMenu.vue";

export default {
    name: "App",
    components: { CategoryMenu, FooterBar, NavMenu },

    data: () => ({
        categories: [],
        managed: true,
    }),
    methods: {
        loadCategories() {
            axios.get("/api/v1/category")
                .then((res) => { 
                    this.categories = res.data.categories
                    this.managed = res.data.managed == "true"
                })
                .catch((err) => console.log(err))
        }
    },
    mounted() {
        this.loadCategories()
    }
};
</script>
