<template>
    <v-dialog v-model="show" max-width="600px">
        <v-card>
            <v-card-title>Добавление категории</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="title" label="Название" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="slug" label="Ссылка" required></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" text @click="createCategory">Добавить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
import axios from 'axios';

export default {
    name: "CategoryCreate",
    props: ["value"],

    data: () => ({
        title: null,
        slug: null,
        category: null,
    }),
    computed: {
        show: {
            get() { return this.value },
            set(value) { return this.$emit('input', value)}
        },
    },
    methods: {
        createCategory() {
            if (this.title != null && this.slug != null) {
                this.show = false
                axios.post("/manage/category/", {title: this.title, slug: this.slug}).then(
                    () => this.$emit('refresh')
                ).catch(
                    (err) => console.log(err)
                )

                this.title = null
                this.slug = null
            }
        },
    }
}
</script>