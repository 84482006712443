<template>
    <v-breadcrumbs divider=">" :items="items" class="pb-0">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    name: "BreadCrumbs",
    props: ["category", "product"],

    data: () => ({

    }),
    computed: {
        items: function() {
            let result = [{text: "Главная", disabled: false, href: "/#/"}]
            if (this.category) {
                result = [
                    {text: "Главная", disabled: false, href: "/#/"},
                    {text: this.category.Title, disabled: true, href: `#/catalog/${this.category.Slug}/`}
                ]
            }
            if (this.category && this.product) {
                result = [
                    {text: "Главная", disabled: false, href: "/#/"},
                    {text: this.category.Title, disabled: false, href: `#/catalog/${this.category.Slug}/`},
                    {text: this.product.Title, disabled: true, href: `#/product/${this.product.Slug}/`},
                ]
            }
            return result
        },
    },
}
</script>